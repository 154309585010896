import React from "react";
import "./disconnected.css"

export const Disconnected = () => {
  return (
    <div className="disconnected">
      <img
        className="walletImg"
        src={require("../../assets/images/general/wallet.png")}
        alt="wallet"
      />
      <p className="disconnected-text">
        Connect your wallet to use
        <br />
        Fock Store
      </p>
    </div>
  );
};
