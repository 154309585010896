import React from "react";
import "./customModal.css";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModalMessage } from "../../redux/reducer";

export const ModalMessage = () => {
  const modalMessage = useSelector(state => state.market.modalMessage)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(setModalMessage(""))
  }

  return (
    modalMessage && (
      createPortal(
      <div className="modalmessage-Wrapper">
          <div className="modalmessage-Content">
           {modalMessage}
          </div>
          <div className="modalmessage-ButtonWrapper">
            <button className="modalmessage-button" onClick={closeModal}>Accept</button>
          </div>
      </div>, document.getElementById("modal"))
    )
  );
}

export const Modal = ({closeModal = () => {}, buttons, content}) => {

  return (
    (
      createPortal(
      <div className="modalmessage-Wrapper">
          <div className="modalmessage-Content">
           {content}
          </div>
          <div className="modalmessage-ButtonWrapper">
            {buttons}
            <button className="modalmessage-button" onClick={closeModal}>Accept</button>
          </div>
      </div>, document.getElementById("modal"))
    )
  );
}