import { ethers } from "ethers";
import ABI from "../../constants/ABI.json"
import {contractAddress} from "../../constants/Web3Constants"
import { errorFormater } from "../errorFormater";

export const claim = async (utilityId) => {
    try {
        const { ethereum } = window;
        const contractABI = ABI.abi;
        // if(!utilityId) throw new Error("there is no associated product")
        if (!ethereum) throw  new Error("Ethereum object doesn't exist!")
       
        const address = sessionStorage.getItem("address")
        if(!address) throw  new Error("Theres not an address")
        
       
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
    
        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
      
        let tx = await contract.claim(utilityId)
        let response = await tx.wait()
        
        return response

      } catch (e) {
        throw new Error(errorFormater(e))
      }
}