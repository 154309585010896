import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"
import { getConfig } from "../helpers/netWorking/GetConfig";

export const GETSliderService = async () => {
    try {

    var configs = await getConfig("GET");
    const response = await customFetch(`${URI}/slider`, configs);

    return response;

  } catch (e) {
    throw e
  }
}
