import { ethers } from "ethers";
import ABI from "../../constants/ABI.json"
import {contractAddress} from "../../constants/Web3Constants"
import { errorFormater } from "../errorFormater";

export const setApproval = async (revokeApproval = false) => {
    try {
        const { ethereum } = window;
        const contractABI = ABI.abi;
  
        if (!ethereum) throw  new Error("Ethereum object doesn't exist!")
       
        const address = sessionStorage.getItem("address")
        if(!address) throw  new Error("Theres not an address")

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
    
        const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
        );
        if(revokeApproval){
            let response = await contract.setApprovalForAll(contractAddress, !revokeApproval)
            return response
        }
        
        let response = await contract.isApprovedForAll(address, contractAddress);
        if(!response){
            response = await contract.setApprovalForAll(contractAddress, !revokeApproval)
        }

        return response
      } catch (e) {
        throw new Error(errorFormater(e))
      }
}