import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"
import { getConfig } from "../helpers/netWorking/GetConfig";

export const GETCatalogService = async (
    offset = 1,
    filterBy = [],
    limit = 8,
    sortBy = '',
    orderBy = '',
    ) => {
    try {
    
      let filterQuery = "";
    
      filterBy.forEach(item =>{
        filterQuery += `&filterBy[]=${item}`
      })

      var configs = await getConfig("GET");
        const response = await customFetch(`${URI}/catalog?offset=${offset}&limit=${limit}&sortBy=${sortBy}
&orderBy=${orderBy}${filterQuery}`, configs);

    return response;
  } catch (e) {
    throw e
  }
}
export const GETALLCatalogService = async (filterBy) => {
  try {

     
    let filterQuery = "";
    
    filterBy.forEach(item =>{
      filterQuery += `&filterBy[]=${item}`
    })

      var configs = await getConfig("GET");
      const response = await customFetch(`${URI}/catalog?offset=1&limit=100000&ShowAll=true${filterQuery}`, configs);

  return response;
} catch (e) {
  throw e
}
}
