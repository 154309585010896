import React, { useEffect, useState } from 'react'
import "./healthpack.css";
import { ModalAliens } from '../../../components/ModalAliens';
import defaultAlien from '../../../assets/images/animations/healthpack/alienEmpty.png';
import healthpackUrl from '../../../assets/images/animations/healthpack/healthpack.jpg';
import { GETAliensService } from '../../../services/AliensService';
import { useDispatch } from 'react-redux';
import { setLoader, setModalMessage } from '../../../redux/reducer';
import { POSTUseUtilitieService, PUTUseConfirmService, PUTUseRejectService } from '../../../services/UtilitieService';
import { getBalance } from '../../../helpers/web3/getBalance';
import { useNavigate } from 'react-router-dom';
import { claim } from '../../../helpers/web3/claim';
import { texts } from '../../../constants/texts';
import { Polaroid } from '../../polaroid';
import { utilityIDs } from '../../../constants/Web3Constants';
import { setApproval } from '../../../helpers/web3/setApproval';

export const Healthpack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [working, setworking] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [done, setdone] = useState(false)
  const [actualAlien, setActualAlien] = useState({
    id:null,
    url:defaultAlien
  });
  const [aliens, setAliens] = useState([]);
  const [imgResponse, setimgResponse] = useState("")
  
  useEffect(() => {
    const init = async () => {
      try {
        dispatch(setLoader(true));
        let res = await getBalance(utilityIDs.healthpack);

        if (res <= 0) {
          navigate("/");
          dispatch(setModalMessage("Sorry! you need to buy a eyedrop to use in this site"));
        }

        res = await GETAliensService("healthpack");
        setAliens(res.data);
      } catch (er) {
        dispatch(setModalMessage(er?.message))
      }
      dispatch(setLoader(false));
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

const buttonHandler = async() => {
  var responseUseUtility;
  try{
    if(working) return;
    if(!actualAlien.id){
      dispatch(setModalMessage(texts.alien_not_selected))
      return;}
    
    setworking(true);
    await setApproval();
    responseUseUtility = await POSTUseUtilitieService(utilityIDs.healthpack, actualAlien.id);
    await claim(utilityIDs.healthpack)
    let responseConfirm = await PUTUseConfirmService(responseUseUtility.activityID)
    setimgResponse(responseConfirm.imageUrl)

    setdone(true)

  }catch(er){
    setworking(false)
    responseUseUtility?.activityID && await PUTUseRejectService(responseUseUtility.activityID)
    dispatch(setModalMessage(er?.message))

  }
}
const openAlienModalHandler = () => {
  if(working || done) return;
  setIsOpen(true)
}
  return (
    <>
        {imgResponse && <Polaroid imgSrc={imgResponse} setimgSrc={setimgResponse}/>}
        <div className={`healthpack-animationWrapper ${working && !done? "healthpack-working" : ""}`} >
            <div className='healthpack-item'>
              <img src={healthpackUrl} width="80%" alt=""/>
            </div>
            <div onClick={openAlienModalHandler} className='healthpack-alien'>
              <img src={actualAlien.url} width="100%" alt=""/>
            </div> 
            <div className={`healthpack-button ${working && !done? "pressed" : ""}`} onClick={buttonHandler}></div>
            <div className='healthpack-coin'></div>
        </div>
        <ModalAliens open={isOpen} onClose={() => setIsOpen(false)} aliens={aliens} setActualAlien={setActualAlien} />
    </>
  )
}
