
import {useEffect, useState} from 'react'
import { providerOptions } from "../constants/Web3Constants";
import Web3Modal from "web3modal";
import { useDispatch, useSelector } from 'react-redux'
import { setIsAuthorized, setAddress, setFocks, setModalMessage } from '../redux/reducer';
import { GETUserService } from '../services/UserService';
import { env } from '../env';
import { useNavigate } from 'react-router-dom';

//ALERT: the chainId should ALWAYS be in lowercase, otherwise it wont work :)

const ConfigChainId =  env.REACT_APP_NETWORK === "goerli" ? "0x5" : "0x1";
export const useConnectWallet = () => {

  const dispatch = useDispatch()
  const address = useSelector(state => state.market.address)
  const navigate = useNavigate()
  const [web3Modal, setWeb3Modal] = useState(null);
  const [IsEthNetwork, setIsEthNetwork] = useState(true); //if you r in the eth net is true
  const [IsWalletConnected, setIsWalletConnected] = useState(false); //virtual connection
  const [showEthAlert, setshowEthAlert] = useState(false); //show the alert if you arent in the eth net


  const connectWallet = async() => {
    try{
      const provider = await web3Modal.connect();
      const accounts = await provider.request({
        method: "eth_requestAccounts"
      })
      //dispatch(setLoader(false))

      dispatch(setAddress(accounts[0]));

      setshowEthAlert(provider.chainId !== ConfigChainId);
      setIsEthNetwork(provider.chainId === ConfigChainId);
      setIsWalletConnected(true);
      
    provider.on("accountsChanged", (accounts) => { //listeners
      setIsWalletConnected(accounts.length !== 0);
      
      if(accounts.length !== 0){
        dispatch(setAddress(accounts[0]));
        navigate(0)
      }else{
        dispatch(setAddress(""));
      }
    });
    
    provider.on("chainChanged", (chainId) => {
      console.log("chainChanged ", chainId)
      console.log("setshowEthAlert", (chainId !== ConfigChainId))
      setshowEthAlert(chainId !== ConfigChainId)
      setIsEthNetwork(chainId === ConfigChainId)
    });

  }catch(er){
    dispatch(setModalMessage("Login rejected by user"));
  }
  }

  const disconnectWallet = async () => {
    try{
      const provider = await web3Modal.connect();
      provider.removeAllListeners("accountsChanged")
      provider.removeAllListeners("chainChanged")
      setIsWalletConnected(false);

    }catch(er){
    }
  }
  const connectBtnHandler = () => {
    if(IsWalletConnected){
 
      disconnectWallet()
      return
    }

    connectWallet()
  }

  const GetUserFocksHandler = async () => {
    try{
      let res = await GETUserService()
      dispatch(setFocks(res.totalFocks))
    }catch(e){
      throw e
    }
  }


  const connectPageHandler = async () => {
    try{

      if(address && IsWalletConnected){
        if(IsEthNetwork){
          dispatch(setIsAuthorized(true))
          GetUserFocksHandler();
          return
        }else{
          dispatch(setIsAuthorized(false))
          const provider = await web3Modal.connect();
          provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: ConfigChainId }],
          });
          return
        }
      }
      
      dispatch(setIsAuthorized(false))
    }catch(e){
      console.log("error request change", e)
    }
  }

  useEffect(() => {
      connectPageHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsEthNetwork, IsWalletConnected])

  useEffect(() => {
    if(web3Modal && web3Modal.cachedProvider){
      connectWallet()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Modal])

  useEffect(() => {
    var _Web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        network: env.REACT_APP_NETWORK ?? "mainnet", // goerli | mainnet
        providerOptions, // required
    });

    setWeb3Modal(_Web3Modal);

  }, []);


  return [connectBtnHandler, IsWalletConnected, showEthAlert, setshowEthAlert]
}
