import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { setLoader, setModalMessage } from '../../redux/reducer'
import { GETActivityService } from '../../services/ActivityService'
import "./activity.css"

export const Activity = () => {
    const [items, setitems] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
        try{
            dispatch(setLoader(true))
            let res = await GETActivityService();
            setitems(res.data);
        }catch(er){
            dispatch(setModalMessage(er?.message))
        }
        dispatch(setLoader(false))
        }
        
        init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
  return (
    <div>
        {
            Object.keys(items).map(key => {
                var toReturn = [];
                toReturn.push(<h3 className='activity-date' key={key}>{key}</h3>)

                items[key].forEach(activity => {
                    toReturn.push(
                    <div className='activity-item' key={activity.ID}>
                        <div className='activity-item-time'>{activity.time}</div>
                        <div>- {activity.description}</div>
                    </div>)
                })

                return toReturn
            })
        }
    </div>
  )
}
