import React, { useState} from 'react'
import { Card } from '../../components/card'
import "./inventory.css"
import { texts } from '../../constants/texts'
import { InventoryFilter } from './inventoryFilter'
import { RedeemModal } from './redeemModal'
import { utilityIDs } from '../../constants/Web3Constants'
import { useNavigate } from "react-router-dom";


export const Inventory = () => {

  const navigate = useNavigate();
  const [filteredItem, setfilteredItem] = useState([])
  const [useItem, setuseItem] = useState(null)

  const CardHandler = (item) => {
    const {isDigital, utilityID} = item

    if(!isDigital){
      setuseItem(item)
      return;
    }

    if(utilityID === utilityIDs.healthpack){
      navigate(`/microsites/healthpack`)
    }else if(utilityID === utilityIDs.soap){
      navigate(`/microsites/soap`)
    }else if(utilityID === utilityIDs.eyedrop){
      navigate(`/microsites/eyedrop`)
    }

  }
  return (
      <div className="catalog-wrapper">
        {useItem && <RedeemModal {...useItem} rejectButton={()=>setuseItem(null)}/>}
        <InventoryFilter setfilteredItem={setfilteredItem}/>
        {filteredItem.length === 0 ? 
        <button onClick={() => {navigate(`/`)}} className='connectWalletBtn emptyInventory_filter'>{texts.empty_inventory}</button>
        :
        <div className="catalog__cards-wrapper">
          {filteredItem.map((item, index) => {
            return <div key={item.ID + index}>
              <Card {...item} onClick={()=> CardHandler(item)}/>
              <button onClick={()=> CardHandler(item)} className='inventory__card-button' style={{color: item.color, border: `2px solid ${item.color}`}}>{item.isDigital ? "USE" : "REDEEM"}</button>
            </div>
          })}
        </div>
      }
      </div>
  )
}


