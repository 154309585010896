import React from "react";
import { FockPrice } from "../fockPrice";
import "./card.css";
import { ReactComponent as Community } from "../../assets/images/icons/community.svg";

export const Card = ({ imageUrl, price, name, color, ownable, quantity, onClick = () => {} , baghead}) => {
  if(price === 0 && baghead) 
    return <BagHeadCard ownable={ownable} name={name} imageUrl={imageUrl}/>
  
  return (
    <div className="card" style={{ borderColor: color }} onClick={onClick}>
      {!ownable && (
        <div className="card__community">
          <Community style={{ fill: color }} />
        </div>
      )}
      <div
        className="card__img-wrapper"
        style={{
          background: `radial-gradient(circle at center, ${color} -50%, transparent 70%)`,
        }}
      >
        <img src={imageUrl} alt="Item"/>
      </div>
      <hr style={{ backgroundColor: color }} />
      <div className="card__content">
        {!quantity ? (
          <FockPrice color={color} size={20}>
            {price}
          </FockPrice>
        ) : (
          <span style={{color: color}}>x{quantity}</span>
        )}
        <hr style={{ backgroundColor: color }} />
        <div style={{ color: color, height: 38 }}>{name}</div>
      </div>
    </div>
  );
};

const BagHeadCard = ({ownable, name, imageUrl}) => {
  let color = "#aeafae";
  return (
    <div className="card card-bagHead" style={{ borderColor: color }}>
      {!ownable && (
        <div className="card__community">
          <Community style={{ fill: color }} />
        </div>
      )}
      <div
        className="card__img-wrapper"
        style={{
          background: `radial-gradient(circle at center, ${color} -50%, transparent 70%)`,
        }}>
        <img alt="Incoming" src={imageUrl} />
      </div>
      <hr style={{ backgroundColor: color }} />
      <div className="card__content">
          <FockPrice color={color} size={20} parse={false}>
            ?
          </FockPrice>
        <hr style={{ backgroundColor: color }} />
        <div style={{ color: color }}>{name}</div>
      </div>
    </div>
  );
}