import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import perfil from "../../../../assets/images/icons/perfil.png"
import "./menu.css"

export const Menu = () => {

    const [showMenu, setshowMenu] = useState(false);

  return (
    <>
    <div className='menu'>
        <img className='menu__img' alt="menu" src={perfil} onClick={() => setshowMenu(prev => !prev)}/>
        {showMenu && <div className='menu__links-wrapper' onClick={()=> setshowMenu(false)}>
            <Link to="/" >- MARKET</Link>
            <Link to="/inventory" >- INVENTORY</Link>
            <Link to="/transfer" >- TRANSFER</Link>
            <Link to="/details" >- DETAILS</Link>
            <Link to="/activity" >- ACTIVITY</Link>
        </div>}
    </div>
    {showMenu && <div onClick={()=>setshowMenu(false)} className='menu_background'></div>}
    </>
  )
}
