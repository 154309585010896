import React, {useState, useEffect} from 'react'
import { GETDetailsService } from '../../services/DetailsService'
import "./details.css"
import detailsLine from "../../assets/images/icons/detailsLine.png"
import background from "../../assets/images/general/background.png"
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll'
import { useDispatch } from 'react-redux'
import { setLoader, setModalMessage } from '../../redux/reducer'
import { texts } from '../../constants/texts'
export const Details = () => {
  
  const [maxOffset, setmaxOffset] = useState(1)
  const [content, setContent] = useState([])
  const [ref, offset] = useInfiniteScroll();
  const dispatch = useDispatch()
  useEffect(() => {
    const init = async () => {
      try{
        if(maxOffset < offset) return;
        dispatch(setLoader(true))
        let data = await GETDetailsService(offset)
        dispatch(setLoader(false))
        setContent(prev => prev.concat(data.data))
        setmaxOffset(data.total_pages)

      }catch(er){
          dispatch(setModalMessage(er?.message))
      }
      dispatch(setLoader(false))
      }
      
      init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])
  
  return (
    <>
      <div className='details__item-wrapper' style={{backgroundImage: `url(${background})`}}>
        {
          content.length === 0 ? 
          <div style={{paddingTop: 40}}>{texts.empty_details}</div> 
          :
          content.map((item, index)=> {
            return <Item key={item.ID} {...item} reverse={index % 2}/>
          })
        }
      </div>
      <div ref={ref}></div>
    </>
  )
}


export const Item = ({imageUrl, text, reverse}) => {
  return (
    <div className={`details__item ${reverse ? "reverse" : ""}` }>
      <div className="details__item-img-wrapper" >
        <img alt="detail img" src={imageUrl}/>
      </div>
      <img alt="line" className='details__item-line' src={detailsLine}/>
      <div className="details__item-text" dangerouslySetInnerHTML={{__html: text}}></div>
    </div>
  )
}

