import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"
import { getConfig } from "../helpers/netWorking/GetConfig";

export const GETDetailsService = async (
    offset = 1,
    limit = 10,
    filterBy = '',
    filterValue = '',
    ) => {
    try {

        var configs = await getConfig("GET");
        const response = await customFetch(`${URI}/tutorial?offset=${offset}&limit=${limit}
&filterBy=${filterBy}&filterValue=${filterValue}`, configs);

    return response;
  } catch (e) {
    throw e
  }
}
