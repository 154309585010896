import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  address: "",
  focks: 0,
  isAuthorized: false,
  showLoader: false,
  modalMessage: ""
}

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      sessionStorage.setItem('address', action.payload);
      state.address = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload
      if(!action.payload)state.focks = 0
    },
    setLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    setFocks: (state, action) => {
      state.focks = action.payload;
    },
    setModalMessage:  (state, action) => {
      state.modalMessage = action.payload;
    }
  
  },
})

export const { setAddress, setIsAuthorized, setLoader, setFocks, setModalMessage } = marketSlice.actions

export default marketSlice.reducer