import { getConfig } from "../helpers/netWorking/GetConfig";
import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"

export const SignatureMessageService = async (address) => {
  try {
    var configs = await getConfig("GET");
    const response = await customFetch(`${URI}/message?address=${address}`, configs);

    return response;
  } catch (e) {
    throw e
  }
}
