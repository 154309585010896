import React, { useEffect, useState } from "react";
import { Card } from "../../components/card";
import { Filter } from "../../components/filter";
import { texts } from "../../constants/texts";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { GETCatalogService } from "../../services/CatalogService";
import "./catalog.css";
import { PurchaseUtilitieModal } from "./purchaseUtilitieModal";

export const Catalog = () => {
  const [buyUtility, setbuyUtility] = useState()
  const [utilities, setUtilities] = useState([]);
  const [maxUtilities, setmaxUtilities] = useState(0)
  const [filter, setFilter] = useState([]);
  const [loadMoreRef, offset, resetOffset, init] = useInfiniteScroll(false);

  useEffect(() => {
    resetOffset()
    setmaxUtilities(0)
    setUtilities([])
    //if(offset === 1)
    console.log("get data effect")
    getData(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])
  
  useEffect(() => {
    if((maxUtilities === utilities.length && maxUtilities != 0) || offset == 1) return
    console.log("getData offset", offset)
    getData(offset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const getData  = (_offset) => {
      GETCatalogService(_offset, filter).then((res) =>{
        setUtilities((prev) => prev.concat(res.data))
        setmaxUtilities(res.total_records)
        init()
      }
    )
  }
  const CardHandler = (_utility) =>{
   setbuyUtility(_utility)
  }

  const ClearModal = () => {
    setbuyUtility()
  }
  return (
    <>
   {buyUtility && <PurchaseUtilitieModal  {...buyUtility} rejectButton={ClearModal}/>}
      <div className="catalog-wrapper">
        <Filter setValue={setFilter} />
        {utilities.length === 0 && <div>{texts.empty_product}</div>}
        <div className="catalog__cards-wrapper">
          {/* {utilities.filter(item => item.price !== 0).map((item, index) => {
            return <Card key={item.ID + index} {...item} onClick={() => CardHandler(item)} />;
          })
           }
          {(maxUtilities === utilities.length) && (
            utilities.filter(item => item.price === 0).map((item, index) => {
                return <Card key={item.ID + index} {...item} baghead/>;
            }))
          }             */}

          {
            utilities.map((item, index) => {
              if(item.price === 0)
              {return <Card key={item.ID + index} {...item} baghead/>}
              return <Card key={item.ID + index} {...item} onClick={() => CardHandler(item)} />;
            })
          }
        </div>
      </div>
      <div ref={loadMoreRef}></div>
    </>
  );
};
