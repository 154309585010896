import React from 'react'
import {ReactComponent as FockIcon} from '../../assets/images/icons/fock.svg'
import { ParseInt } from '../../helpers/ParseInt'
import "./fockPrice.css"

export const FockPrice = ({children = 15, size = 15, color = "#CCFFCC"}, parse = true) => {

  return (
    <div className='fockPriceWrapper'>
        <FockIcon style={{height: size, fill: color}}/>
        <div style={{fontSize: size, color: color}}>{parse ? ParseInt(children) : children}</div>
    </div>
  )
}
