import { customFetch } from "../helpers/netWorking/CustomFetch";
import { URI } from "../constants/NetWorking";
import { getConfig } from "../helpers/netWorking/GetConfig";

export const GETGasPriceService = async () => {
  try {
    var configs = await getConfig("GET");
    const response = await customFetch(`${URI}/gasprice`, configs, 120000);

    return response;
  } catch (e) {
    throw e;
  }
};
