import React from 'react'
import "./filter.css"

export const Filter = ({setValue}) => {

    const setValueHandler = (e) => {
        
      let value = e.target.value
      if(e.target.checked)
        setValue(prev => [...prev, value])
      else
        setValue(prev => {
          let aux = [...prev]

          aux.forEach((element, index) => {
            if(element === value)
              aux.splice(index, 1)
          })
          return aux
        })
    }

  return (
    <div className='filter-wrapper'>
        <input type="checkbox" name="filter" value="digital" id="digital" onChange={(e => setValueHandler(e))}/>
        <label htmlFor="digital">DIGITAL</label><br />

        <input type="checkbox" name="filter" value="physical" id="physical" onChange={(e => setValueHandler(e))}/>   
        <label htmlFor="physical">PHYSICAL</label><br />

        <input type="checkbox" name="filter" value="community" id="community"onChange={(e => setValueHandler(e))}/>
        <label htmlFor="community">COMMUNITY</label><br />
    </div>
  )
}
