import { ethers } from "ethers";
import ABI from "../../constants/ABI.json"
import {contractAddress} from "../../constants/Web3Constants"
import { errorFormater } from "../errorFormater";

export const getBalance = async (utilityId) => {
    try {
      const { ethereum } = window;
      const contractABI = ABI.abi;

      if (!ethereum) throw  new Error("Ethereum object doesn't exist!")
       
      const address = sessionStorage.getItem("address")
      if(!address) throw  new Error("Theres not an address")


        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
   
        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer
        );
     
        let response = await contract.balanceOf(
            address,
            utilityId
        );

        return response.toNumber()
      
    } catch (e) {
      throw new Error(errorFormater(e))
    }
  };


export const getBalanceOfBatch = async (utilityIds) => {
  try {
    const { ethereum } = window;
    const contractABI = ABI.abi;

    if (!ethereum) throw  new Error("Ethereum object doesn't exist!")
     
    const address = sessionStorage.getItem("address")
    if(!address) throw  new Error("Theres not an address")


      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
 
      const contract = new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );
      const addresses = Array.from({length: utilityIds.length}, () => address)
      let response = await contract.balanceOfBatch(
          addresses,
          utilityIds
      );

      return response
    
  } catch (e) {
    console.log("error balance batch ", e)
    throw new Error(errorFormater(e))
  }
};
