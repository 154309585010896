import { ethers } from 'ethers';
import { SignatureMessageService } from '../services/SignatureMessageService';
import { errorFormater } from './errorFormater';

const SignatureHelper =  async() =>{
    try{
        var signature, msg, address;
        
        address = sessionStorage.getItem("address");
        signature = sessionStorage.getItem("signature");
        msg = sessionStorage.getItem("msg");

        if(signature && address){
            const addressVerify = await ethers.utils.verifyMessage(msg, signature);

            if(addressVerify.toLowerCase() === address.toLowerCase())
            return [msg, signature, address];
        }

        ({msg} = await SignatureMessageService(address))
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        signature = await signer.signMessage(msg);

        sessionStorage.setItem('signature', signature);
        sessionStorage.setItem('msg', msg);
        return [msg, signature, address];

    }catch(e){
        throw new Error(errorFormater(e))
    }


}

export default SignatureHelper