import { texts } from "../constants/texts";

export const errorFormater = (er) => {
    let message = er?.message ?? er?.msg ?? er?.error?.message;
    if(!message) return texts.error

    if(message?.toLowerCase()?.indexOf("insufficient_funds") !== -1){
        message = "Insufficient funds";
    }else if(message?.toLowerCase()?.indexOf("user denied transaction signature") !== -1 || message?.toLowerCase()?.indexOf("user rejected") !== -1){
        message = "User denied transaction signature";
    }else if(message?.toLowerCase()?.indexOf("qty tokens would exceed max supply") !== -1){
        message = "Quantity exceed max supply";
    }else if(message?.toLowerCase()?.indexOf("user denied message signature") !== -1){
        message = "User denied message signature";
    }else if(message?.toLowerCase()?.indexOf("the user aborted a request") !== -1){
        message = texts.time_out
    }else if(message?.toLowerCase()?.indexOf("balance must be greater than zero") !== -1){
        message = "You doesn´t have that NFT to be claimed"
    }else if(message?.toLowerCase()?.indexOf("cannot estimate gas") !== -1){
        message = "Cannot estimate gas of this transaction."
    }

    return message ?? texts.error;
}