
import { useState, useRef, useCallback, useEffect } from 'react';


export const useInfiniteScroll = (startInitValue = true) => {
  const [page, setPage] = useState(1);
  const [start, setstart] = useState(startInitValue);

  const loadMoreRef = useRef(null);

  const init = useCallback(() => {
    setstart(true)
  },[])
  const handleObserver = useCallback((entries) => {
    const [target] = entries;
    if (target.isIntersecting && start) {
      setPage((prev) => {
        return prev + 1});
    }
  }, [start]);

  const resetOffset = () => {
    setPage(1)
  //  setstart(false)
  }
  useEffect(() => {
    const option = {
      rootMargin: '20px',
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
  }, [handleObserver]);

  return [loadMoreRef, page, resetOffset, init ];
}
