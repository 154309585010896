import { getConfig } from "../helpers/netWorking/GetConfig";
import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"

export const POSTTransferService = async (amount, address) => {
  try {
    let body = {
        transfers: [
            {
                address: address,
                amount: amount
            }
        ]
    }

    var configs = await getConfig("POST", body, true);
    const response = await customFetch(`${URI}/transfer`, configs);

    return response;
  } catch (e) {
    throw e
  }
};
