import React from 'react'
import "./navbar.css"
import logo from "../../assets/images/general/logo.webp"
import { ConnectWallet } from './components/connectWallet'
import { FockPrice } from '../../components/fockPrice'
import { Menu } from './components/menu'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Navbar = () => {

  const focks = useSelector((state) => state.market.focks);

  return (
    <div className='navbar'>
      <Link to="/" style={{display: "flex",  width: "min-content"}}>
        <img src={logo} className="logo" alt="logo"/>
      </Link>
        <div className='navbar-fock'>
         <FockPrice size={30}>{focks}</FockPrice>
        </div>
        <div className='navbar__buttons'>
          <Menu />
          <ConnectWallet />
        </div>
    </div>  
    )
}
