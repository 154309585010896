export const texts = {
    buy_warning: "Please ensure that you have the necessary GAS before claiming an NFT from our marketplace. Once METAMASK is opened, $FOCKs will be permanently deducted, and this operation cannot be reversed. If you do not have enough ETH for GAS, please reject the transaction.",
    redeem_utility: name => `Great! You redeem a ${name}`,
    modal_aliens_no_content: "Fock, you don't have NFTs to choose from. Grab one at ",
    transfer: (amount, address, total) => `You sent ${amount} $FOCK to\n ${address}\n Your new balance is ${total} $FOCK`,
    empty_inventory: "Fock, your inventory is empty",
    empty_product: "Nothing to watch here yet!",
    empty_details: "No details yet!",
    alien_not_selected: "Please choose an NFT",
    not_found : "Fock 404 A.K.A. check the focking URL",
    error: 'Fock, something went wrong',
    errorTypes:{
        claim: "Please accept to burn your NFT",
        mint: "Fock, something went wrong during the mint",
        setApproval: "We need your allowance to burn your NFT"
    },
    time_out: "Time out focker!"
}