import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { FockPrice } from '../fockPrice';
import "./line.css"
export const Line = ({location = false, showFocks = true, showOnlyFocks = false}) => {
  
  const actualLocation = useLocation();
  const focks = useSelector(state => state.market.focks)
  
    if(showOnlyFocks){
      return <div className='line-fock' style={{padding: 20}}>
      <FockPrice size={20}>{focks}</FockPrice>
    </div>
    }
    return (
      <>
          <div className='line-info'>
              {showFocks && <div className='line-fock'>
                  <FockPrice size={20}>{focks}</FockPrice>
              </div>}
            {(location && !actualLocation.pathname.includes("microsites") && actualLocation.pathname.replaceAll("/", "")) &&  <h1 className='line__pathname'>{actualLocation.pathname.replaceAll("/", "")}</h1>}
          </div>
          <div className='line'/>
      </>
  )
}
