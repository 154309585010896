import { ethers } from "ethers";
import ABI from "../../constants/ABI.json"
import {contractAddress} from "../../constants/Web3Constants"
import { errorFormater } from "../errorFormater";

export const getMaxUtility = async () => {
    try {
      const { ethereum } = window;
      const contractABI = ABI.abi;

      if (!ethereum) throw  new Error("Ethereum object doesn't exist!")

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();

      const contract = new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );

      let response = await contract.maxUtilityId();
      return response.toNumber()
      
    } catch (e) {
      throw new Error(errorFormater(e))
    }
  };