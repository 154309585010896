import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"
import { getConfig } from "../helpers/netWorking/GetConfig";

export const GETActivityService = async (
    offset = 1,
    limit = 10000000) => {
    try {

      var configs = await getConfig("GET");
      const response = await customFetch(`${URI}/activity?offset=${offset}&limit=${limit}`, configs);

    return response;
  } catch (e) {
    throw e
  }
}
