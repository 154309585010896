import React, { useState, useEffect } from "react";
import "./purchaseUtilitieModal.css";
import { createPortal } from "react-dom";
import { FockPrice } from "../../../components/fockPrice";
import { POSTPurchaseService, PUTConfirmPurchaseService, PUTRejectPurchaseService } from "../../../services/PurchaseService";
import { useDispatch } from "react-redux";
import { setFocks, setLoader, setModalMessage } from "../../../redux/reducer";
import { mint } from "../../../helpers/web3/mint";
import { env } from "../../../env";
import { texts } from "../../../constants/texts";
import { Modal } from "../../../components/ModalMessage";
import {useNavigate} from "react-router-dom";
import { GETGasPriceService } from "../../../services/GasPrice";
import { ReactComponent as Community } from "../../../assets/images/icons/community.svg";

export const PurchaseUtilitieModal = ({
  ID,
  imageUrl,
  price,
  name,
  color,
  isDigital,
  rejectButton,
  ownable
}) => {
  const [gasPrice, setgasPrice] = useState("");
  const [warningAccepted, setwarningAccepted] = useState(false);
  const [transactionHash, settransactionHash] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
        GETGasPriceService().then(data => {
          setgasPrice(`${data.aux.slice(0, 5)} gwei`);
        })
    };

    init();
  }, []);

  const confirmHandler = async () => {
    
    var response;

    try {
      if (!warningAccepted) return;

      dispatch(setLoader(true));
      response = await POSTPurchaseService(ID);
      dispatch(setFocks(response.focks));
      var mintResponse = await mint(
        response.utilityId,
        price,
        response.hash,
        response.nonce,
        response.signature
      );
      await PUTConfirmPurchaseService(response.activityID);
      settransactionHash(mintResponse.transactionHash);

    } catch (er) {
      if(response?.activityID){
        let rejectResponse = await PUTRejectPurchaseService(response.activityID).catch(er=>{})
        rejectResponse.focks && dispatch(setFocks(rejectResponse.focks));
      }
      dispatch(setModalMessage(er?.message))
      closeModal();
    }
    dispatch(setLoader(false));
  };

  const FinalModalContent = () => {
    const getUrl = () => {
      if (env.REACT_APP_NETWORK === "goerli")
        return `https://goerli.etherscan.io/tx/${transactionHash}`;

      return `https://etherscan.io/tx/${transactionHash}`;
    };

    return (
      <div>
        <div>{`DONE. you got your ${name}!`}</div>
        <div>
          <a
            href={getUrl()}
            target="_blank"
            rel="noreferrer"
            className="purchaseModal-link"
          >
            Click here
          </a>{" "}
          to view the transaction.
        </div>
      </div>
    );
  };
  const closeModal = () => {
    rejectButton();
    if (transactionHash)
      navigate("/inventory")
  };
  if (transactionHash)
    return <Modal closeModal={closeModal} content={FinalModalContent()} />;

  const modalStyle = {"boxShadow": `0px 0px 40px -10px ${color}, inset 0px 0px 40px -10px ${color}`,
  color: color,
  border: `2px solid ${color}`
}

  return createPortal(
    <div className="purchaseModal-Wrapper">
      <div>
        <div className="purchaseModal-Modal" style={modalStyle}>
        {!ownable && (
          <div className="purchaseModal-community">
            <Community style={{ fill: color }} />
          </div>
        )}
          <div className="purchaseModal-content">
            <div className="purchaseModal-image-wrapper">
              <img className="purchaseModal-image" src={imageUrl} alt="item" />
            </div>
            <div className="purchaseModal-info">
              <h3>{name}</h3>
              <FockPrice size={25}>{price}</FockPrice>
              <div>{isDigital ? "Digital" : "Physical"} utility</div>
              <div className="purchaseModal-Footer">
                Estimated gas {gasPrice ? gasPrice : "..."}
              </div>
            </div>
          </div>
          <div className="purchaseModal-warning">
            <div className="purchaseModal-warningText">{texts.buy_warning}</div>
            <div className="purchaseModal-checkboxWrapper">
              <input
                className="purchaseModal-checkbox"
                type="checkbox"
                id={"warning"}
                onChange={() => setwarningAccepted((prev) => !prev)}
              />
              <label
                style={{ cursor: "pointer", userSelect: "none" }}
                htmlFor="warning"
              >
                I understand
              </label>
            </div>
          </div>
        </div>
        <div className="purchaseModal-ButtonWrapper">
          <button className="purchaseModal-cancel" onClick={closeModal}>
            Reject
          </button>
          <button
            className="purchaseModal-button"
            disabled={!warningAccepted}
            onClick={confirmHandler}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
};
