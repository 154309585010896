import { getConfig } from "../helpers/netWorking/GetConfig";
import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"

export const POSTPurchaseService = async (productID) => {
  try {

    let body = {
        productID: productID
    }

    var configs = await getConfig("POST", body, true);
    const response = await customFetch(`${URI}/product/purchase`, configs);

    return response;
  } catch (e) {
    throw e
  }
};

export const PUTConfirmPurchaseService = async (activityID) => {
  try {

    let body = {
        activityID: activityID
    }

    var configs = await getConfig("PUT", body, true);
    const response = await customFetch(`${URI}/product/purchase/confirm`, configs);

    return response;
  } catch (e) {
    throw e
  }
};

export const PUTRejectPurchaseService = async (activityID) => {
  try {

    let body = {
      activityID: activityID
    }

    var configs = await getConfig("PUT", body, true);
    const response = await customFetch(`${URI}/product/purchase/reject`, configs);

    return response;
  } catch (e) {
    throw e
  }
};
