import React, {useEffect, useState} from 'react'
import { getBalanceOfBatch } from '../../../helpers/web3/getBalance'
import { getMaxUtility } from '../../../helpers/web3/getMaxUtility'
import { GETALLCatalogService } from '../../../services/CatalogService'
import { Filter } from "../../../components/filter";
import { setLoader, setModalMessage } from '../../../redux/reducer'
import { useDispatch } from 'react-redux'

export const InventoryFilter = ({setfilteredItem}) => {

  const [items, setitems] = useState([])
  const [filter, setfilter] = useState([])
  const dispatch = useDispatch()


    useEffect(() => {
      
        filterData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [filter])
    
    const getData = async () => {
      try{
        dispatch(setLoader(true))
    
        let [utilities, maxUtility] = await Promise.all([
          GETALLCatalogService(filter),
          getMaxUtility()])
    
        utilities = utilities.data
      
      if(maxUtility === 0){
        dispatch(setLoader(false))
          return;
      }
      var inventory = [];
      const tokenIds = Array.from({length: maxUtility}, (_, i) => i + 1)
      let response = await getBalanceOfBatch(tokenIds)

      response.forEach((number, index) => {
        number = number.toNumber()
        if(number > 0){
             var utility = utilities.find(item => item.utilityID === index + 1)
         
             if(utility)
             inventory.push({
                  quantity: number,
                  ...utility
                })
            }
      })
      // var aux = [];
      // for (let index = 0; index <= maxUtility; index++) {
        // let response = await getBalance(index)
    
      //   if(response > 0){
      //    var utility = utilities.find(item => item.utilityID === index)
     
      //    if(utility)
      //     aux.push({
      //         quantity: response,
      //         ...utility
      //       })
      //   }
      // }
    
      setitems(inventory)
      setfilteredItem(inventory)
    }catch(er){
      dispatch(setModalMessage(er?.message))
    }  
    dispatch(setLoader(false))
    
    }
    
    const filterData = async() => {
      try{
        if(items.length === 0) {
          getData()
          return
        }
    
        var userItems = [...items]
    
        if(filter.length === 0 || filter.length === 3){ // all filters or none
          setfilteredItem(userItems);
          return;
        }
    
        if(filter.length === 1 ){ // just one filter
          let aux = [];
    
          userItems.forEach(item => {
            if(filter.includes("community")){
              if(!item.ownable){
                aux.push(item)
              }
            }else{
              let type = filter.includes("digital") ? 1 : 0
    
              if(item.isDigital === type){
                aux.push(item)
              }
            }
            setfilteredItem(aux)
          })
          return;
        }
    
        if(filter.length === 2 && filter.includes("community")){ //community + physical/digital
          let aux = [];
          let type = filter.includes("digital") ? 1 : 0
          userItems.forEach(item => {
            if(!item.ownable && item.isDigital === type){
              aux.push(item)
            }
            setfilteredItem(aux)
          })
          return;
        }
    
      }catch(er){
        dispatch(setModalMessage(er?.message))
      }  
    
    }

  return (
    <Filter setValue={setfilter} />
  )
}
