import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { env } from "../env";
export const contractAddress = env.REACT_APP_CONTRACT_ADDRESS;

export const providerOptions = {
    coinbasewallet: {
      package: CoinbaseWalletSDK, // Required
      options: {
        appName: "The Alien Boy Marketplace", // Required
        infuraId: "597f81dddea0492ca599a7e789349477", // Required
        chainId: 1, // Optional. It defaults to 1 if not provided
      }
    },
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: "597f81dddea0492ca599a7e789349477",
        }
    },
    fortmatic: {
        package:  window.Fortmatic,
        options: {
            key: "pk_live_C95B642EE4E358FD" // production
        }
    }
};

export const utilityIDs = {
    healthpack: 1,
    soap: 2,
    eyedrop: 3
}