import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import transfer from "../../assets/images/animations/transfer/transfer.png";
import transferMobile from "../../assets/images/animations/transfer/transferMobile.png";
import { texts } from "../../constants/texts";
import { ParseAddress } from "../../helpers/ParseAddress";
import { ParseInt } from "../../helpers/ParseInt";
import { setFocks, setLoader } from "../../redux/reducer";
import { POSTTransferService } from "../../services/TransferService";

import "./transfer.css";


export const Transfer = () => {
  const dispatch = useDispatch()
  const address = useSelector((state) => state.market.address);
  const focks = useSelector(state => state.market.focks);
  const [response, setresponse] = useState("")
  const [toAddressRef, settoAddressRef] = useState("")
  const [amountRef, setamountRef] = useState("")
  const [loading, setloading] = useState(false)
  

useEffect(() => {
   dispatch(setLoader(true))
   setTimeout(() => {
    dispatch(setLoader(false))
  }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const transferHandler = async () => {

    try{
      if(response !== ""){
        setresponse("")
        setamountRef("")
        settoAddressRef("")
        return;
      }
      
      if(amountRef > focks || toAddressRef.toUpperCase() === address.toUpperCase() || !amountRef || loading) return;

      setloading(true)
      let res = await POSTTransferService(amountRef, toAddressRef)
      
      dispatch(setFocks(res.balance))
      
      setresponse(texts.transfer(ParseInt( amountRef), ParseAddress(toAddressRef), ParseInt(res.balance)))
    }catch(er){
      setresponse(er.message)
    }
    setloading(false)
  }


const fockInputHandler = (e) => {
  var value = e.target.value
  value = value.replace(/[^0-9]/g, '');
  if(!!parseInt(value)){
    value = parseInt(value).toString()
  }
  setamountRef(value)
}

const isButtonDisabled = () => {
  //if button disabled then return true

  if(response) return false
  return amountRef > focks || toAddressRef.toUpperCase() === address.toUpperCase() || !amountRef || loading
}
  return (
    <div className="trasnfer">
      <img className="transfer__machine-desktop" src={transfer} alt="transfer machine"/>
      <img className="transfer__machine-mobile" src={transferMobile} alt="transfer machine"/>
        {
          loading ? <img className="transfer_spinner" src={require("../../assets/images/general/coin.gif")} alt="spinner"/>
          : 
          <>
          <img className="transfer__machine-press" alt="button" src={require("../../assets/images/animations/transfer/transfer_pressButton.png")} />
            <div className="transfer__text">
              {response ? <div className="transfer-message">{response}</div> :
              <>
                <div>
                  <span>FROM ADDRESS</span>
                </div>
                <div>
                  <span>{ParseAddress(address)}</span>
                </div>
                <div>
                  <span>TRANSFER </span>
                  <input className="transfer__fockInput" style={{width: 200}} value={amountRef} onChange={fockInputHandler} />
                  <span> FOCK</span>
                </div>
                <div>
                  <span>TO ADDRESS </span>
                </div>
                <div>
                  <input value={toAddressRef} onChange={(e)=>settoAddressRef(e.target.value)} type="text" />
                </div>
              </>
            }
          </div>
        </>
        }
        
      {response ? <div className="transfer__button transfer__button-newtransfer" onClick={transferHandler} />
      : <div className="transfer__button" style={isButtonDisabled() ? {cursor: "not-allowed", filter: "grayscale(1)"} : {}}  onClick={transferHandler} />
      }
    </div>
  );
};
