import { React} from "react";
import "./connectWallet.css";
import { useConnectWallet } from "../../../../hooks/useConnectWallet";
import { env } from "../../../../env";

export const ConnectWallet = () => {
  const [connectBtnHandler, IsWalletConnected, showEthAlert, setshowEthAlert] = useConnectWallet();

  return (
    <>
      <div className={`EthAlert ${showEthAlert ? "EthAlertOpen" : ""}`}> 
      Oops! Please change to the {env.REACT_APP_NETWORK || "mainnet"} network
        <div onClick={() => setshowEthAlert(false)}>x</div>
        </div>
      <div className="connectWalletWrapper">
      <button onClick={connectBtnHandler}
          className={"connectWalletBtn"}>{IsWalletConnected ? "Disconnect" :  "Connect"  }
      </button>
      </div>
    </>
  );
}

