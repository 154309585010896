import React, {useState, useEffect} from "react";
import { createPortal } from "react-dom";
import { texts } from "../../constants/texts";
import "./ModalAliens.css";

export const ModalAliens = ({
  open,
  aliens = [],
  onClose,
  setActualAlien,
}) => {
  
  const selectAlien = (alien) => {
    setActualAlien({
      id: alien.alien,
      url: alien.imageUrl,
    });
    onClose();
  };
  if (!open) return <></>;
  return createPortal(
    <>
      <div className="modalAliensOverlay" />
      <div className="modalAliensContainer">
        <div className="modalAliens">
          <button className="modalAliensClose" onClick={onClose}>
            X
          </button>
          {aliens.length > 0 ? (
            <>
            <h2 className="modalAliensTitle">CHOOSE YOUR NFT</h2>
                <div className="modalAlienCards">
                {aliens.map((alien, i) => {
                    return (
                        <div className="modalAlienCard" key={i}>
                        <ImageContainer
                          alien={alien}
                          selectAlien={selectAlien}
                          scale={0.02}
                        />
                    </div>
                    );
                })}
                </div>
            </>
          ) : (
            <h2 className="modalAliensTitle">{texts.modal_aliens_no_content}
            <a href="https://opensea.io/collection/thealienboy" target="_blank" rel="noreferrer">here</a>
            </h2>

          )}
        </div>

        <div className="modalAliensBorder"></div>
      </div>
    </>,
    document.getElementById("modal")
  );
};


const ImageContainer = ({  alien, selectAlien}) => {
 
  const src = alien.imageUrl;
  const [isLoaded, setIsLoaded] = React.useState(false);

  const handleImageLoaded = () => {

        setIsLoaded(true);
  };

  return (
    <>
      {!isLoaded && <Spinner />}
      <img
        src={src}  
        alt={"Alien " + alien.alien}
        width={"175px"}
        onClick={() => selectAlien(alien)}
        style={{ display: isLoaded ? 'block' : 'none' }}
        onLoad={handleImageLoaded}
      />
    </>
  );
//code if we hade a thumbnail from backend
  // const imgSrc = alien.imageUrl;

  // const [ usedSrc, setUsedSrc ] = useState(spinnerGif);
  // const [ usedEffectStyle, setUsedEffectStyle ] = useState({ filter: 'blur(5px)', clipPath: 'inset(0)' });

  // useEffect(() => {
  //     const img = new Image();
  //     img.src = imgSrc;
  //     img.onload = () => {
  //         setUsedSrc(img.src);
  //         setUsedEffectStyle({});
  //     }
  // }, []);

  // return <img src={usedSrc} 
  //  style={{ transition: 'filter 0.1s ease-out', ...usedEffectStyle }} 
  //  alt={"Alien " + alien.alien}
  //  width={"175px"}
  //  onClick={() => selectAlien(alien)}
  //  />;


};


const Spinner = () => {
  return (
    <div className="spinner-container-modal">
      <div className="spinner-modal"></div>
    </div>
  );
};
