import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import { GETSliderService } from "../../services/SliderService";
import { useDispatch } from "react-redux";
import { setModalMessage, setLoader } from "../../redux/reducer";

export const Carousel = () => {
  const dispatch = useDispatch();
  const [sliders, setsliders] = useState([]);
  // const [loader, setloader] = useState(true)

  

  useEffect(() => {
    
    const init = async () => {
    try{
      dispatch(setLoader(true))
      let res = await GETSliderService();
      setsliders(res.data);
    }catch(er){
      dispatch(setModalMessage(er?.message))
    }
    dispatch(setLoader(false))
    }
    
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

const NextArrow = ({ onClick }) => {
  return (
    <div
      className="NextArrow"
      onClick={onClick}
    />
  );
}
const PrevArrow = ({onClick }) => {
  return (
    <div
      className="PrevArrow"
      onClick={onClick}
    />
  );
}

const settings = {
  dots: false,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 3000,
  speed: 500,
  arrow: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
};

  if (sliders.length === 0) return <></>;
  return (
    <div style={{ marginBottom: 30 }}>
      {/* <Loader customShow = {loader}/> */}
      <Slider {...settings}>
        {sliders.map((item) => {
          if (item.url) {
            return (
              <a key={item.ID} href={item.url} target="_blank" rel="noreferrer" style={{ cursor: "pointer" }}>
                <img alt="Slider item" src={item.imageUrl} />
              </a>
            );
          }
          return <img alt="Slider item" key={item.ID} src={item.imageUrl} />;
        })}
      </Slider>
    </div>
  );
};
