import { texts } from "../../constants/texts";
import { errorFormater } from "../errorFormater";

export const customFetch = async (url, configs, ms = 45000) => {
    try{
        
        var controller = new AbortController();
        var signal = controller.signal;

        setTimeout(() => {
            controller.abort();
        }, ms);

        configs = {...configs, signal}

        let response = await fetch(url, configs);
        if(response.status === 404){
            throw new Error(texts.not_found);
        }
        let data = await response.json();
        if (response.ok) return data;
        if(data.msg || data.message)
            throw data;

        if(Array.isArray( data?.errors) &&  data?.errors[0]?.msg){
            throw new Error(data?.errors[0]?.msg);
        }

        throw ""
    }catch(e){
        throw new Error(errorFormater(e));
    }
    
};
    
