import React,{useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import Spinner from "../../assets/images/general/spinner.gif"
import "./spinner.css"

export const Loader = ({customShow = null}) => {
  
  const reduxShow =  useSelector(state => state.market.showLoader);
  const show = customShow ?? reduxShow;


  const [isShowingControl, setisShowingControl] = useState(false);
  const [ActivatedDate, setActivatedDate] = useState(new Date())

  useEffect(() => {
    if(!isShowingControl && !show)
      return;
      
    if(show){
      setisShowingControl(true);
      setActivatedDate(new Date());
      return;
    }
    let timePassed =  (new Date() - ActivatedDate);
    if( timePassed < 1000){ //to let the spinner activated at least 1 second to not look like a glitch
      let timeout = 1000 - (timePassed);
      setTimeout(() => {
        setisShowingControl(false)
      }, timeout);
    }else{
      setisShowingControl(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])



  //if(!isShowingControl ) return <></>

  return (
    <div className={ `spinner ${isShowingControl ? "" : "hideSpinner" }`}>
        <img src={Spinner} alt="spinner"/>
    </div>
  )
}
