import { getConfig } from "../helpers/netWorking/GetConfig";
import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"

export const POSTUseUtilitieService = async (productID, alienAddress) => {
  try {

    let body = {
        utilityID: productID,
        alienAddress: alienAddress
    }

    var configs = await getConfig("POST", body, true);
    const response = await customFetch(`${URI}/product/use`, configs);

    return response;
  } catch (e) {
    throw e
  }
};

export const PUTUseConfirmService = async (activityID) => {
  try {

    let body = {
        activityID: activityID,
    }

    var configs = await getConfig("PUT", body, true);
    const response = await customFetch(`${URI}/product/use/confirm`, configs, 120000);

    return response;
  } catch (e) {
    throw e
  }
};

export const PUTUseRejectService = async (activityID) => {
  try {

    let body = {
        activityID: activityID,
    }

    var configs = await getConfig("PUT", body, true);
    const response = await customFetch(`${URI}/product/use/reject`, configs, 120000);

    return response;
  } catch (e) {
    throw e
  }
};