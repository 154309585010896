import { ethers } from "ethers";
import ABI from "../../constants/ABI.json";
import { contractAddress } from "../../constants/Web3Constants";
import { errorFormater } from "../errorFormater";

export const mint = async (utilityId, price, hash, nonce, signature) => {
  try {

    console.log("mint", utilityId, nonce, signature);

    const contractABI = ABI.abi;
    const { ethereum } = window;

    if (!utilityId) throw new Error("there is no associated product")
    if (!ethereum) throw new Error("Ethereum object doesn't exist!")

    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(contractAddress, contractABI, signer);
    console.log("contract ", contract);
    const price = 0;

    const gasConfig = await getGasConfig(
      contract,
      provider,
      utilityId,
      nonce,
      signature
    );

    let overrides = {
      value: price,
      gasLimit: gasConfig.gasLimit,
      gasPrice: gasConfig.gasPrice,
    };

    let tx = await contract.mint(
      utilityId,
      1, //quantity, always 1
      nonce,
      signature,
      overrides
    );

    let response = await tx.wait();
    return response;
  } catch (e) {
    console.log("Mint error ", e)
    throw new Error(errorFormater(e))
  }
};


/**
 * Get optimized gas.
 * @param s object Contract instance.
 * @param numOfTokens int Tokens amount.
 * @param price BigNumber  Price in ETH.
 * @param provider provider
 * @return object
 */
async function getGasConfig(s, provider, utilityId, nonce, signature) {
  try {
    // El incremento de porcentaje de gas se recienda poner en el archivo .ENV
    const PERCENTAGE_INCREASE_GAS_LIMIT = 120; // 20%

    const BN = ethers.BigNumber;
    const ZERO_BN = ethers.constants.Zero;
    let gasConfig = { gasLimit: ZERO_BN, gasPrice: ZERO_BN };
    let gasLimit = null;

    console.log("1")
    gasLimit = await s.estimateGas.mint(
      utilityId,
      1, //quantity, always 1
      nonce,
      signature
    );
    if (!gasLimit) return false;
    console.log("4")
    gasConfig.gasLimit = gasLimit
      .mul(BN.from(PERCENTAGE_INCREASE_GAS_LIMIT))
      .div(BN.from(100));
    console.log("2")
    // Method 1: Fetch gasPrice
    // let gasPrice = await web3Provider.getGasPrice();
    let gasPrice = await provider.getGasPrice();//ethers.utils.formatUnits(aux, "gwei");
    console.log("3")
    // Check against maxGasPrice
    gasConfig.gasPrice = gasPrice;
    return gasConfig;
  } catch (e) {
    console.log("calculate gas ", e)
    throw e
  }
}
