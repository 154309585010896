import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import "./polaroid.css"

export const Polaroid = ({imgSrc, setimgSrc, text = "HEALED"}) => {

    const navigate = useNavigate()
    const [clicked, setclicked] = useState(false)

    const polaroidHandler = () => {
        setclicked(true)
        setTimeout(() => {
            navigate("/")
            //setimgSrc("")
        }, 1000);
    }
  return (
    <div className={`polaroid-back ${clicked ? "polaroid-back-hide" : ""}`}>
        <div className='polaroid-wrapper'>
            <img className='polaroid-img' alt="new alien" src={imgSrc}/>
            <div className='polaroid-text'>{text}</div>
        </div>
        <div className='polaroid-continue' onClick={polaroidHandler}>CLICK TO CONTINUE</div>
    </div>
  )
}
