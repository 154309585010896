import "./App.css";
import React from "react";
import { Navbar } from "./containers/navbar";
import { useSelector } from "react-redux";
import { Catalog } from "./containers/catalog";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Details } from "./containers/details";
import { Line } from "./components/line";
import { Footer } from "./components/footer";
import { Transfer } from "./containers/transfer";
import { Inventory } from "./containers/inventory";
import { Carousel } from "./components/carousel";
import { Loader } from "./components/loader";
import { Disconnected } from "./containers/disconnected";
import { Activity } from "./containers/activity";
import { ModalMessage } from "./components/ModalMessage";
import { Healthpack } from "./containers/useUtilities/healthpack";
import { Soap } from "./containers/useUtilities/soap";
import { Eyedrop } from "./containers/useUtilities/eyedrop";
import { CustomButton } from "./components/customButton"
import { setApproval } from "./helpers/web3/setApproval";

function App() {
  const authorized = useSelector((state) => state.market.isAuthorized);
  var location = useLocation();

  return (
    <div>
      <Navbar />
      
      {(location.pathname === "/"  && authorized)&& (<><Line showOnlyFocks/> <Carousel /></>)}
      <Line location showFocks={location.pathname !== "/"}/>
      
      <div className="App">
        <Loader />
        <ModalMessage />
        {authorized ? (
          <>
            <Routes>
              {/* <Route path="/revoke" element={RevokePermission()}/> */}
              <Route path="/" element={<Catalog />} />
              <Route path="/details" element={<Details />} />
              <Route path="/transfer" element={<Transfer />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/activity" element={<Activity />} />
              <Route
                  path="*"
                  element={<Navigate to="/" replace />}
              />
              <Route path="microsites">
                <Route path="healthpack" element={<Healthpack />}/>
                <Route path="soap" element={<Soap />}/>
                <Route path="eyedrop" element={<Eyedrop />}/>
              </Route>
            </Routes>
          </>
        ) : (
          <Disconnected />
        )}
      </div>
      <Footer />
    </div>
  );
}

const RevokePermission = () => {
  return (
    <div>
      <h1>Revoke permission to access to your tokens</h1>
      <CustomButton text={"Revoke"} onclick={() => setApproval(true)}/>
    </div>
  )
}
export default App;
