import SignatureHelper from '../SignatureHelper';

const getHeader = async (wantSecurity) => {

    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Accept'

    }
    let address = sessionStorage.getItem("address");
    header.address = address

    if(wantSecurity){
        const [msg, signature] = await SignatureHelper();
        header.message = msg.replaceAll(`\n`, `+`);
        header.signature = signature;
    }
    return header
}

export const getConfig = async (method, body, wantSecurity = false) => {
    let header = await getHeader(wantSecurity)

    return {
        headers: header,
        method: method,
        body: body&&JSON.stringify(body)
    };
}