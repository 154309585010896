import React from 'react'
import discord from "../../assets/images/socialMedia/discord.png"
import instagram from "../../assets/images/socialMedia/instagram.png"
import opensea from "../../assets/images/socialMedia/opensea.png"
import twitter from "../../assets/images/socialMedia/twitter.png"
import { Line } from '../line'
import "./footer.css"
export const Footer = () => {


  return (
    <>
    <Line showFocks={false}/>
    <div className='footer'>
        <a href="https://twitter.com/TheAlienBoyNFT"  target="_blank" rel="noreferrer" >
          <img src={twitter}  alt="twitter"/>
        </a>
        <a href="https://discord.com/invite/TheAlienBoy" target="_blank" rel="noreferrer" >
          <img src={discord} alt="discord"/>
        </a>
        <a href="https://opensea.io/collection/thealienboy"  target="_blank" rel="noreferrer" >
          <img src={opensea}  alt="opensea"/>
        </a>
        <a href="https://www.instagram.com/thealienboy_nft/"  target="_blank" rel="noreferrer" >
          <img src={instagram}  alt="instagram"/>
        </a>
    </div>
    </>
  )
}
