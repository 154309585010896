import { customFetch } from "../helpers/netWorking/CustomFetch";
import {URI} from "../constants/NetWorking"
import { getConfig } from "../helpers/netWorking/GetConfig";

export const GETAliensService = async (type = '',) => {
    try {

      var configs = await getConfig("GET", null);
      const response = await customFetch(`${URI}/aliens?type=${type}`, configs, 120000);

    return response;
  } catch (e) {
    throw e
  }
}